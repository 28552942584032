/**
Basic Table Styles
*/

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

table.simple1 {
    width: 100%;
    text-align: left;
    border-spacing: 0;
    border-collapse: collapse;
    border-radius: 4px;
    border-style: hidden;
    box-shadow: 0 0 0 1px #D3D3D3;
}

table.simple1 tbody tr td {
    padding: 16px 8px;
    border-left: 1px solid #D3D3D3;
    border-bottom: 1px solid #D3D3D3;
}

table.simple1 tbody tr td:last-child {
    border-right: 1px solid #D3D3D3;
}

table.simple1 tbody tr:nth-child(2n) td,
table.simple1 tbody tr td {
    border-bottom: 1px solid #D3D3D3;
}

